
<template>
  <div 
    :class="{
      'modal': true, 
      'modal-fullscreen': isMobileResize, 
      'is-mobile-margin': isMobileMargin, 
      'transparent': isTransparent, 
      'auto-height': isAutoHeight, 
      'is-rounded': isRounded,
      'enable-header': title,
      'enable-footer': primaryButtonName || closeButtonName
    }" 
    v-on:click.self="closeModal"
    >
    <div class="modal-dialog" :style="{ width: width, maxWidth: maxWidth, minWidth: minWidth, height: height }">
      <div class="modal-content">
        <div class="close" aria-label="Close" v-on:click="closeModal">
          <span aria-hidden="true">&times;</span>
        </div>
        <div class="modal-header" v-if="title">
          <div class="modal-title">{{ title }}</div>
        </div>
        <div class="modal-body" :class="{'modal-body-scroll': isScrollContent}">
          <slot></slot>
        </div>
        <div v-if="primaryButtonName || closeButtonName" class="modal-footer">
          <UiButton 
            v-if="primaryButtonName" 
            type="primary" 
            v-on:click="primaryAction" 
            :name="primaryButtonName"
            :is-loading="primaryButtonIsLoading"
            />
          <UiButton 
            v-if="closeButtonName" 
            type="button" 
            v-on:click="closeModal" 
            :name="closeButtonName"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

// const isLocked = useScrollLock(el)
// isLocked.value = true // lock
// isLocked.value = false // unlock

const { $noscroll } = useNuxtApp();

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  primaryButtonName: {
    type: String,
    default: null
  },
  primaryButtonIsLoading: {
    type: Boolean,
    default: false
  },
  closeButtonName: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '300px'
  },
  minWidth: {
    type: String,
  },
  maxWidth: {
    type: String,
  },
  height: {
    type: String,
    default: 'inherit'
  },
  isMobileResize: {
    type: Boolean,
    default: false
  },
  isScrollContent: {
    type: Boolean,
    default: false
  },
  isTransparent: {
    type: Boolean,
    default: false
  },
  isAutoHeight: {
    type: Boolean,
    default: false
  },
  isRounded: {
    type: Boolean,
    default: false
  },
  isMobileMargin: {
    type: Boolean,
    default: false
  },
})

const emits = defineEmits(['update:modelValue', 'close', 'primary'])

const closeModal = () => {
  console.log('into modal event close')
  emits('update:modelValue', false)
  emits('close')
}
const primaryAction = () => {
  emits('primary')
}

onMounted(() => {
  $noscroll.on();
});

onUnmounted(() => {
  $noscroll.off();
});

onBeforeUnmount(() => {
  $noscroll.off();
});
 
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0 0 0 / 84%);
  z-index: 11100;
  height: 100dvh;
  width: 100%;
}

.modal-fullscreen {
  padding: 0;
}

.modal-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color:  var(--un-background-color);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  /* max-height: 90dvh; */
}

.modal-dialog .modal-content{
  height: fit-content;
  max-height: inherit;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.modal-dialog .modal-content .modal-body{
  /* height: fit-content; */
  max-height: inherit;
}
.modal-body-scroll {
  overflow-y: auto;
}

.close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  right: 0px;
  position: absolute;
  top: 0px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 12111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
  border-bottom: 1px solid var(--un-element-color-gray);
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  position: relative;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: bold; 
  flex-shrink: 0;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 1px;
}
.modal.enable-header .modal-body,
.modal.enable-footer .modal-body {
  height: calc(100% - 53px);
}
.modal.enable-header.enable-footer .modal-body {
  height: calc(100% - 106px);
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid var(--un-element-color-gray);
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
  gap: 10px;
  flex-shrink: 0;
}

.btn {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}


/* transparent */
.transparent .modal-dialog {
  background-color: transparent;
    border: 0;
}
.transparent .modal-header{
  border:0;
}

.transparent .modal-footer{
  border:0;
}
.transparent .modal-title,
.transparent .close{
  color: white;
}

.transparent .modal-body{
  height: auto;
  max-height: calc(100dvh - 110px);
  overflow-y: auto;
}
.modal.is-rounded .modal-dialog {
    border-radius: 20px;
}


@media (max-width: 768px) {
  .modal {
    height: 100dvh;
  }
  .modal-dialog {
    margin: 0;
    width: 100%!important;
    height: 100%;
    max-width: 100%!important;
    max-height: 100dvh;
    border-radius: 0;
    border:0;
  }
  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .close{

  }
  .modal-header {
    border-radius: 0;
  }
  .modal-body {
    flex: 1 1 auto;    
    height: calc(100dvh - 110px);
    /* overflow-y: hidden; */
  }
  .modal-footer {
    border-radius: 0;
  }
  .modal-body-scroll {
    overflow-y: auto;
  }
  .modal-fullscreen {
    padding: 0;
  }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen .modal-body {
    flex: 1 1 auto;
    padding: 0;
  }
  .modal-fullscreen .modal-footer {
    border-radius: 0;
  }
  .modal.is-mobile-margin .modal-dialog{
    max-width: calc(100% - 3rem)!important;
  }
}


/* auto height */


.modal.auto-height .modal-dialog {
  height: auto!important;
  max-height: 100dvh;
}
@media (max-width: 768px) {

  .modal.auto-height .modal-body{
    height: auto;
  }
}

</style>
